import React from "react";
import PropTypes from "prop-types";

export default function FullWidthImage(props) {
  const {
    height = 400,
    img,
  } = props;

  return (
    <React.Fragment>
      <div
        className="margin-top-0"
        style={{
          display: "grid",
          alignItems: "center",
        }}
      >
        <img
          src={img}
          style={{
            gridArea: "1/1",
            objectFit: "cover",
            objectPosition: "top",
            // You can set a maximum height for the image, if you wish.
            height: height,
            width: "100%",
          }}
          // This is a presentational image, so the alt should be an empty string
          alt=""
        />
        <div
          style={{
            // By using the same grid area for both, they are stacked on top of each other
            gridArea: "1/1",
            position: "relative",
            // This centers the other elements inside the hero component
            placeItems: "center",
            display: "grid",
          }}
        >
          {/* Any content here will be centered in the component */}
          <h1
            className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
            style={{
              boxShadow:
                "rgb(57 86 116 / 42%) 0.5rem 0px 0px, rgb(57 86 116 / 42%) -0.5rem 0px 0px",
              backgroundColor: "rgb(57 86 116 / 42%)",
              color: "white",
              lineHeight: "1.1",
              padding: "0.25em 0.5em",
              textAlign: "center",
            }}
          >
            <span className="is-inline-block">テクノロジーで</span>
            <span className="is-inline-block">未来を創る</span>
          </h1>
          <p
            className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
            style={{
              boxShadow:
                "rgb(57 86 116 / 42%) 0.5rem 0px 0px, rgb(57 86 116 / 42%) -0.5rem 0px 0px",
              backgroundColor: "rgb(57 86 116 / 42%)",
              color: "white",
              lineHeight: "1.1",
              padding: "0.5rem",
              marginTop: "1rem",
              textAlign: "center",
            }}
          >
            <span className="is-inline-block">WEB開発・システム設計の</span>
            <span className="is-inline-block">株式会社フィロクラフト</span>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}

FullWidthImage.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  height: PropTypes.number,
};
